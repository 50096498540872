<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>

        </v-app-bar>
        <v-card-text class="row content mx-0">
        
            <v-row align-content="center" justify="center">
                <v-col cols="10" md="8" lg="6">

                    <v-card-title class="headline pl-0" style="word-break: break-word;">Welcome to the LFA rental shop</v-card-title>
                    <v-card-subtitle class="pl-0">Please select an option below to continue</v-card-subtitle>

                    <v-row>
                        <v-col cols="12">
                            <v-card flat color="grey lighten-4" class="hover" @click="toPath('rent')">
                                <v-card-text>
                                    <div class="title">I want to rent</div>
                                    <div>Browse our packages and selecte the perfect outfit for you.</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card flat color="grey lighten-4" class="hover" @click="toPath('event')">
                                <v-card-text>
                                    <div class="title">I'm part of an event</div>
                                    <div>I am ready to make it official</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card flat color="grey lighten-4" class="hover" @click="toPath('event-status')">
                                <v-card-text>
                                    <div class="title">My event</div>
                                    <div>Check the status of your event</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions({
            setPath: 'setPath'
        }),
        toPath( path ) {
            this.setPath( path );

            if( path == 'rent' )
                this.$router.push('/use-date');
            else if( path == 'event' )
                this.$router.push('/event');
            else 
                this.$router.push('/event-status');
        }
    }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}

.hover:hover {
    background-color: #e7e7e7 !important;
}
</style>